<template>
  <div class="tc-ques-read">
    <!-- 顶部功能 -->
    <!-- <div class="header-tool">
      <div class="del" @click="del">
        <img src="@/assets/images/del.png" alt />
      </div>
      <div class="refresh" @click="refresh()">
        <img src="@/assets/images/refresh.png" alt />
      </div>
    </div> -->

    <div class="top-common">
      <div style="display:flex;align-items: center;">
        <div class="title-text lh72">批阅信息管理</div>
        <!-- 右边 -->
        <div class="right-btn-box ">
          <el-button type="primary" size="mini" class="btn" @click="del">删除</el-button>
        </div>
      </div>   
    </div>
    <!-- 表格 -->
    <div class="content">
      <el-table
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
        @selection-change="handleSelectionChange"
        stripe
        border
        class="tableBox"
      >
        <el-table-column
          type="selection"
          width="60"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="real_name"
          label="用户名称"
          width="350"
          align="left"
          header-align="center"
        ></el-table-column>
        <el-table-column
          prop="update_time"
          label="提交日期"
          width="250"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="core"
          label="当前成绩"
          width="150"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="status"
          label="是否批阅"
          width="150"
          align="center"
        >
          <template slot-scope="scope">
            <span :class="scope.row.status == 0 ? 'off' : 'no'">{{
              scope.row.status == 0 ? "未批阅" : "已阅"
            }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button size="mini" type="plain" @click="Read(scope.row)"
              >查看 / 批改</el-button
            >
            <el-divider direction="vertical"></el-divider>
            <el-button size="mini" type="plain" @click="del(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 页码 -->
    <div class="page" style="text-align: right">
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page="page"
        :page-size="pageSize"
        layout="total, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </div>
  </div>
</template>
<script>
import { listIsanswerUserQue, softDeleteAnswer } from "@/api/questionnaire.js";
export default {
  inject: ["reload"],
  data() {
    return {
      multipleSelection: [],
      tableData: [],
      // 页码
      page: 1,
      pageSize: 15,
      total: 0,
    };
  },
  created() {
    this.loadListData();
  },
  methods: {
    handleCurrentChange(val) {
      this.page = val;
      this.loadListData();
    },
    // 刷新
    refresh() {
      this.reload();
    },
    // 获取全选ID
    handleSelectionChange(val) {
      this.multipleSelection = val;
      let p = [];
      this.multipleSelection.forEach((v) => {
        p.push(v.id);
      });
      this.multipleSelection = p;
    },
    // 数据加载
    loadListData() {
      let data = {
        qsid: this.$route.query.id,
        page: this.page,
        pagesize: this.pageSize,
      };
      listIsanswerUserQue(data).then((res) => {
        this.tableData = res.data;
        this.total = res.count;
      });
    },
    // 前往批阅问卷
    Read(row) {
      let routeData = this.$router.resolve({
        path: "/read_questionnaire_look",
        query: { pid: this.$route.query.id, id: row.id, name: row.real_name },
      });

      // window.open(routeData.href);
      window.location.href = routeData.href;
    },
    // 删除问卷
    del(row) {
      let id = "";
      if (row.id) {
        id = row.id + "";
      } else {
        if (this.multipleSelection.length > 0) {
          id = this.multipleSelection.toString();
        } else {
          this.$message({
            showClose: true,
            message: "还未勾选要删除的问卷!",
            type: "warning",
          });
          return;
        }
      }
      this.$confirm("此操作将永久删除该问卷的答题记录, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          softDeleteAnswer({ id: id }).then((res) => {
            if (res.code == 400200) {
              this.$message({
                type: "success",
                message: "删除成功!",
              });
              this.loadListData();
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
  },
};
</script>
<style lang="less">
.tc-ques-read {
  // 顶部功能
  .header-tool {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .del {
      width: 100px;
      margin-right: 20px;
      cursor: pointer;
    }
    .refresh {
      width: 100px;
      cursor: pointer;
    }
    img {
      width: 100%;
      pointer-events: none;
    }
  }
  //   表格
  .content {
    position: relative;
    height: calc(~"100% - 220px");
    min-height: 600px;
    margin-top: 20px;
    color: rgb(4, 87, 102);
    th {
      color: rgb(4, 87, 102);
      padding: 0;
      height: 40px;
      line-height: 40px;
      background: rgb(217, 242, 247);
    }
    td {
      padding: 0;
      height: 35px;
      line-height: 35px;
    }
    tr.el-table__row--striped td {
      background: rgb(234, 246, 248);
    }
    .el-button + .el-button {
      margin-left: 0px;
    }
    .el-button--mini {
      padding: 4px 4px;
      background: transparent;
      color: rgb(4, 87, 102);
      font-size: 14px;
      &:hover {
        border: 1px solid rgb(105, 105, 105);
      }
    }
    .no {
      color: green;
    }
    .off {
      color: red;
    }
    .el-input__inner {
      width: 50px;
      padding: 5px;
      text-align: center;
    }
  }
}
table{
  color: #100700;
    th {
      color: #4B2000 !important;
      background: #FFDEC6 !important;
      border-right: none !important;
      border-left: none !important;
    }
    td {
      border-right: none !important;
      border-left: none !important;
    }
    .el-button--mini{
      color: #FFAB71 !important;
      padding: 4px 4px;
      background: transparent;
      border: none !important;
      font-size: 14px;
    }
}
</style>